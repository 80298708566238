<template>
  <div class="container">
    <PublicHeader :totalName="$t('Inpour').title" />

    <div class="pay-type">
      <div class="pay" :class="{ active: form.TransferType === 'Bank' }" @click=" form.TransferType = 'Bank'">
        <img src="@/assets/bank.png" alt="" />
        <span>{{ $t('Inpour').Bank }}</span>
      </div>
      <div class="pay" v-if="basicConfig.IsShowCurrency" :class="{ active: form.TransferType === 'USDT' }"
        @click=" form.TransferType = 'USDT'; handleChange_USDT()">
        <img src="@/assets/USDT.png" alt="" />
        <span>USDT</span>
      </div>
      <div class="pay" v-if="basicConfig.IsShowCurrency" :class="{ active: form.TransferType === 'BTC' }"
        @click=" form.TransferType = 'BTC'; handleChange_BTC()">
        <img src="@/assets/BTC.png" alt="" />
        <span>BTC</span>
      </div>
      <div class="pay" v-if="basicConfig.IsShowCurrency" :class="{ active: form.TransferType === 'ETH' }"
        @click=" form.TransferType = 'ETH'; handleChange_ETH()">
        <img src="@/assets/ETH.png" alt="" />
        <span>ETH</span>
      </div>
    </div>
    <!-- 银行卡 -->
    <div class="info" v-if="form.TransferType === 'Bank'">
      <div v-if="basicConfig.BankEnable">
        <van-field v-model="basicConfig.SystemBankName" disabled :label="$t('rechargeService').OpenBank" />
        <van-field v-model="basicConfig.CardNo" disabled :label="$t('bank').card" id="textarea">
        </van-field>
        <van-field v-model="basicConfig.CardUserName" disabled :label="$t('rechargeService').CardUserName" />
      </div>
      <i></i>
      <div class="sleLable">{{ $t('Inpour').SelectAmount }}</div>
      <div class="money-list">
        <span v-for="item in list" :style="{ backgroundImage: 'url(' + item.bg + ')' }" :key="item.money"
          @click="form.Amount = item.money">{{ item.money }}</span>
      </div>

      <van-field class="input" :border="false" v-model="form.Amount" :label="$t('Inpour').Amount"
        :placeholder="placeholder" />
      <div class="bigBtn" @click="handleSubmit">
        {{ $t('Inpour').Submit }}
      </div>
    </div>
    <!-- USDT -->
    <div class="info" v-if="form.TransferType === 'USDT'">
      <div class="address" @click="onCopy(basicConfig.USDTAddress)">{{ basicConfig.USDTAddress }}<img
          src="@/assets/copy.png" width="16px" alt="" /></div>
      <img :src="basicConfig.USDTCode" class="codeimg" />
      <van-field class="input" :border="false" v-model="basicConfig.USDTNetWork" :label="$t('Withdrawal').Network"
        readonly />
      <div class="sleLable">{{ $t('Inpour').SelectAmount }}</div>
      <div class="money-list">
        <span v-for="item in list" :style="{ backgroundImage: 'url(' + item.bg + ')' }" :key="item.money"
          @click="form.Amount = item.money; handleChange_USDT()">{{ item.money }}</span>
      </div>
      <van-field class="input" :border="false" v-model="form.Amount" @input="handleChange_USDT"
        :label="$t('Inpour').Amount" :placeholder="placeholder" />
      <van-field class="input" :border="false" v-model="coinNum" :label="$t('Withdrawal').shouldPutMsg + 'USDT'"
        readonly />
      <div class="sleLable">{{ $t('Withdrawal').payVoucher }}
        <van-uploader class="img-box" preview-size="120" :preview-image="false" :after-read="justAfterRead">
          <img class="upImage" v-if="form.PayVoucher" :src="form.PayVoucher" alt="" />
        </van-uploader>
      </div>
      <div class="bigBtn" @click="handleSubmit">
        {{ $t('Inpour').Submit }}
      </div>
    </div>
    <!-- BTC -->
    <div class="info" v-if="form.TransferType === 'BTC'">
      <div class="address" @click="onCopy(basicConfig.BTCAddress)">{{ basicConfig.BTCAddress }}<img
          src="@/assets/copy.png" width="16px" alt="" /></div>
      <img :src="basicConfig.BTCCode" class="codeimg" />
      <van-field class="input" :border="false" v-model="basicConfig.BTCNetWork" :label="$t('Withdrawal').Network"
        readonly />
      <div class="sleLable">{{ $t('Inpour').SelectAmount }}</div>
      <div class="money-list">
        <span v-for="item in list" :style="{ backgroundImage: 'url(' + item.bg + ')' }" :key="item.money"
          @click="form.Amount = item.money; handleChange_BTC()">{{ item.money }}</span>
      </div>
      <van-field class="input" :border="false" v-model="form.Amount" @input="handleChange_BTC"
        :label="$t('Inpour').Amount" :placeholder="placeholder" />
      <van-field class="input" :border="false" v-model="coinNum" :label="$t('Withdrawal').shouldPutMsg + 'BTC'"
        readonly />
      <div class="sleLable">{{ $t('Withdrawal').payVoucher }}
        <van-uploader class="img-box" preview-size="120" :preview-image="false" :after-read="justAfterRead">
          <img class="upImage" v-if="form.PayVoucher" :src="form.PayVoucher" alt="" />
        </van-uploader>
      </div>
      <div class="bigBtn" @click="handleSubmit">
        {{ $t('Inpour').Submit }}
      </div>
    </div>
    <!-- ETH -->
    <div class="info" v-if="form.TransferType === 'ETH'">
      <div class="address" @click="onCopy(basicConfig.ETHAddress)">{{ basicConfig.ETHAddress }}<img
          src="@/assets/copy.png" width="16px" alt="" /></div>
      <img :src="basicConfig.ETHCode" class="codeimg" />
      <van-field class="input" :border="false" v-model="basicConfig.ETHNetWork" :label="$t('Withdrawal').Network"
        readonly />
      <div class="sleLable">{{ $t('Inpour').SelectAmount }}</div>
      <div class="money-list">
        <span v-for="item in list" :style="{ backgroundImage: 'url(' + item.bg + ')' }" :key="item.money"
          @click="form.Amount = item.money; handleChange_ETH()">{{ item.money }}</span>
      </div>
      <van-field class="input" :border="false" v-model="form.Amount" @input="handleChange_ETH"
        :label="$t('Inpour').Amount" :placeholder="placeholder" />
      <van-field class="input" :border="false" v-model="coinNum" :label="$t('Withdrawal').shouldPutMsg + 'ETH'"
        readonly />
      <div class="sleLable">{{ $t('Withdrawal').payVoucher }}
        <van-uploader class="img-box" preview-size="150" :preview-image="false" :after-read="justAfterRead">
          <img class="upImage" v-if="form.PayVoucher" :src="form.PayVoucher" alt="" />
        </van-uploader>
      </div>
      <div class="bigBtn" @click="handleSubmit">
        {{ $t('Inpour').Submit }}
      </div>
    </div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import transactionApi from '@/api/transaction'
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      coinNum: null,
      form: {
        IsUpper: true,
        Amount: null,
        TransferType: 'Bank',
        PayVoucher: null,
      },
      placeholder: '',
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
    list() {
      let arr = []
      if (this.basicConfig.RechargeAmounts) {
        let a = this.basicConfig.RechargeAmounts.split(',')
        a.forEach((item, index) => {
          if (index > 4) return
          let num = index + 1
          arr.push({
            money: item,
            bg: require(`@/assets/btn_chip_${num}.png`),
          })
        })
      }

      return arr
    },
  },
  methods: {
    handleChange_USDT() {
      this.coinNum = (this.form.Amount / this.basicConfig.USDTRate).toFixed(6)
    },
    handleChange_BTC() {
      this.coinNum = (this.form.Amount / this.basicConfig.BTCRate).toFixed(6)
    },
    handleChange_ETH() {
      this.coinNum = (this.form.Amount / this.basicConfig.ETHRate).toFixed(6)
    },
    async justAfterRead(file) {
      const formdata = new FormData()
      formdata.append('userfile[0]', file.file)
      this.form.PayVoucher = await userApi.upCaptchaFile(formdata)
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('Withdrawal').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        // 创建输入框
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        // 隐藏此输入框
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        // 赋值
        textarea.value = text
        // 选中
        textarea.select()
        // 复制
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('Withdrawal').copySuccess)
        return resolve(true)
      })
    },
    async handleSubmit() {
      let form = { ...this.form }
      if (form.Amount === '') {
        this.$toast({
          message: this.$t('Withdrawal').AmountMessage,
          position: 'bottom',
        })
        return
      }
      if (form.TransferType !== 'Bank') {
        if (!form.PayVoucher) {
          this.$toast({ message: this.$t('Withdrawal').payVoucherMsg })
          return
        }
      }
      form.Amount = Number(form.Amount)
      await transactionApi.TransferRecords(form)
      this.$toast.success(this.$t('public').SubmittedSuccessfully)
      if (this.basicConfig.IsRechargeToService) {
        this.$router.push({
          name: 'RechargeService',
          params: { Amount: form.Amount },
        })
      } else {
        this.$router.push({
          name: 'AccessLog',
        })
      }
    },
    async getUserInfo() {
      let res = await userApi.userInfo()
      this.placeholder =
        this.basicConfig.Symbol +
        res.RechargeLowLimit +
        ' ~ ' +
        this.basicConfig.Symbol +
        (res.RechargeTopLimit ? res.RechargeTopLimit : '')
    },
  },
  created() {
    this.getUserInfo()
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size 12px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding 48.5px 10px 0
  .pay-type
    display flex
  .active
    background: linear-gradient(315deg,#e8d49e,#d6b372)
    border: 1Px solid #f8c653
    color #303030 !important
  .pay
    color #d0c9c9
    width 68px
    height 68px
    margin 10px 6px
    border-radius 5px
    flex-column()
    text-align: center;
    img
      width 33px
      height 33px
      margin-bottom 5px
  p
    font-size: 15px;
    color: #ebe5cd;
    margin 15px 0 25px
  .money-list
    height 55px
    display flex
    justify-content center
    span
      width 54px
      height 100%
      margin 5px
      flex-column()
      background-size 100% 100%
  .input
    color: #fff;
    border-bottom 1Px solid  #40404b
    border-top 1Px solid  #40404b
    background none
    margin-top 28px
    >>> .van-field__control
      color #FFF
    >>> .van-field__label
      color #ebe5cd
    >>> input::-webkit-input-placeholder
      color #969799
  .bigBtn
    width: 100%
    height 40px
    flex-center()
    color #222222
    font-size: 15px
    background: linear-gradient(315deg,#e8d49e,#d6b372)!important;
    margin 25px auto
    border-radius: 5px
  .info
    .van-field
      margin 0
      background none
      >>> .van-field__label
        color #ebe5cd
      >>> .van-field__control
        color #fff
      >>> input::-webkit-input-placeholder
        color #969799
    .sleLable
     font-size: 13px
     margin: 15px 0 0 15px
     display: flex;
    .img-box
     padding-left: 35px
    .upImage
     width: 150px;
    .address
      margin: 5px
      font-size: 13px
      color: #ffffff
      background: #292929
      border-radius: 3px;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    .codeimg
      width: 150px;
      margin: 10px 100px 15px 100px
</style>
